.seriesAutomation {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    margin-left: 50px;

    .seriesAutomationSettings {
        display: flex;
        flex-direction: column;
        position: relative;
        user-select: none;
        gap: 10px;

        .automationChoices {
            left: -75px;
            bottom: 45px;
            width: 329px;
            z-index: 6;

            .radioButtons {
                padding: 23px 10px 0 0;
            }
        }
    }
}