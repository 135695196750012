@import '../../../variables.scss';

.errorContainer {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;

    .cancel {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 10px;
    }
}

.richTextField {
    overflow: hidden;
    > div {
        height: fit-content;
    }
}

.withMaxLimit {
overflow: clip;
overflow-clip-margin: 20px;    
}

.textarea {
    .textareaField {
        max-height: 400px;
        min-height: 150px;
        overflow-y: scroll;
    }

    .textareaContainer {
        height: auto;
    }
}

.addField {
    padding: 6px 15px;
    font-size: 12px;
    color: $primary-color;
    height: 18px;
    align-items: center;
    cursor: pointer;
}

.autoComplete {
    display: flex;
    flex-direction: row;
}

.freeTextDialogContainer {
    &:global(.MuiDialog-paper) {
        overflow: visible;
    }
}

.freeTextDialog {
    width: 400px;
    margin: 20px;
}

.freeTextDialogContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 25px;

    .freeTextDialogTokenPopover {
        right: -340px;
    }
}

.freeTextDialogContainerHeaderTitle {
    font-weight: bold;
}

.freeTextDialogReferenceMessage {
    position: relative;
    font-size: 10px;
    min-height: 10px;
    padding-top: 10px;
    display: flex;
    gap: 5px;
}

.tipContainer {
    display: flex;
    align-items: flex-start;
}

.freeTextDialogReferenceMessageTip {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

$copy-token-background-color: #e9e7cf;
$copied-token-background-color: #fff5cc;

.copyReferenceTokenButton {
    font-size: 12px;
    font-weight: 600;
    color: $primary-color;
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;

    &:hover {
        background-color: $copy-token-background-color;
    }
}

.referenceTokenCopiedContainer {
    position: absolute;
    top: 5px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($copied-token-background-color, 0.8);
    font-size: 14px;
    color: black;

    .text {
        margin-left: 10px;
    }
}