@import '../../../../../../../variables';

.content {
    overflow: auto;
    width: 745px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 30px 26px 40px;
    font-size: 12px;

    .title {
        font-weight: bold;
    }

    .subTitle {
        margin-bottom: 10px;
    }

    .buttonSettingsContainer {
        margin-top: 29px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $separator-color;
        padding-top: 15px;
        width: 100%;

        > div {
            width: 100%;
        }

        .boldTitle {
            align-self: flex-start;
            font-weight: bold;
        }
    }

    .addFieldButton {
        margin-top: 5px;
        @include addItemTextButton();
    }
}

.popupTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 335px;

    .title {
        font-weight: bold;
    }

    padding: 0 0 25px;
}

.titleSettings {
    display: flex;
    flex-direction: row;
    gap: 75px;
    border-bottom: 1px solid $separator-color;
}