.container {
    margin-top: 20px;

    table {
        border-collapse: collapse;

        tr {
            border-bottom: solid 1px #e9e8ed;
        }

        tr:last-child {
            border-bottom: none;
        }

        th {
            font-weight: normal;
        }
    }
}

.tableRow {
    height: 70px;

    &.disabled {
        .column {
            color: #ababab;
        }
    }
}

.columnHeader {
    width: 290px;
    text-align: left;
    height: 25px;
    padding: 0 10px;
}

.column {
    font-size: 14px;
    color: #000000;
    width: 350px;
    min-width: 200px;
    padding-right: 10px;

    .item {
        display: flex;
        align-items: center;

        .arrowsContainer {
            width: 50px;
            height: 65px;
            position: absolute;
            left: -50px;
            display: flex;
            align-items: center;

            .arrows {
                display: flex;
                flex-direction: row-reverse;

                .upArrow {
                    transform: rotate(180deg);
                }

                :nth-of-type(2) {
                    margin-right: 10px;
                }

                .filler {
                    margin-right: 20px;
                }
            }
        }

        .icons {
            margin-left: auto;

            img {
                margin-right: 25px;
            }
        }
    }
}

.centered {
    display: inline-flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.emptyString {
    padding-left: 5px;
}
