@import '../../../variables';

.error {
    position: absolute;
    bottom: 68px;
    right: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}

.customLeftButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.customLeftButtonBorder {
    margin: 0 20px;
    border-left: 1px solid $border-color;
    height: 20px;
}

.mandatoryLabel {
    font-size: 12px;
}

.deleteRecordDialog {
    margin: 15px 30px 30px;
}

.fieldInput {
    margin-bottom: 25px;
    display: inline-block;
    padding-right: 15px;
    box-sizing: border-box;
    vertical-align: top;
}

.emptyState {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.eaObjectEditDialog {
    max-width: 100%;
    overflow: hidden;
    @include dialogContainer();
}

.dialog {
    padding: 20px 30px 20px 18px;
    @include dialogContent();
    @media (max-width: $mobile) {
        width: unset;
    }
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    margin: 0 -15px 0 0; // Negative margin to offset the padding on fieldInput
}