@import '../../../variables';

.dialog {
    width: 600px;
    min-height: 420px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dialogBody {
    flex: 1;
    padding: 10px 30px;
}

.customHeader {
    border: unset;
    height: auto;
}

.header {
    display: flex;
    flex-direction: column;
    padding: 15px 25px 15px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid $separator-color;
    position: relative;
    width: 100%;

    .title {
        font-size: 16px;
    }

    .subTitle {
        font-size: 24px;

        .status {
            font-size: 16px;
            color: $status-text-color;
        }
        .id {
            margin-left: 15px;
            font-size: 12px;
        }
    }
}


.closeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 2px;
    cursor: pointer;
}

.userActions {
    background-color: $placeholder-content-background;
    padding: 13px 30px 20px;
    width: 100%;
    box-sizing: border-box;
}

.contentData {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    max-height: calc(#{$comments-dialog-height} - 5vh);
}

.description {
    white-space: break-spaces;
    padding: 0 5px 10px 15px;
    border-bottom: 1px solid $separator-color;
}

.commentSection {
    white-space: pre-wrap;
}

.loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
}

.attachmentIcon {
    cursor: pointer;
}

.fileUploading {
    display: flex;
    width: 224px;
    height: 30px;
    padding: 3px 10px 3px 10px;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);

    .message {
        margin: 6px 0 4px 10px;
        font-family: $main-Font;
        font-size: 12px;
        color: $subtext-grey;
    }
}

.disableNewComment {
    padding-bottom: 10px;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}