@import './variables';

$ai-pink: #f208f0;
$ai-background-color-gradient: linear-gradient(130deg, $ai-pink -18%, #793cfb 68%, #65daed 112%);
$ai-text-color-gradient: linear-gradient(to right, $ai-pink, #793cfb);
$ai-text-color: #707383;
$ai-suggestion-background-color: #f5f5f5;
$ai-suggestion-hover-background-color: #e1e1e1;

%AI_COLOR_BACKGROUND {
    background-image: $ai-background-color-gradient;
}

%AI_STARS_BACKGROUND {
  background: url('assets/icons/white-stars.svg') no-repeat 34%, $ai-background-color-gradient ;
  background-origin: padding-box;
}

%AI_TEXT {
    background: $ai-text-color-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-family: $main-Font;
}

// For border with gradient color.
@mixin AI_CONTENT_WRAPPER($background-color, $border-radius: 5px, $border-size: 1px) {
    border: $border-size solid transparent !important;
    background: linear-gradient($background-color, $background-color) padding-box,
    $ai-background-color-gradient border-box;
    border-radius: $border-radius;
}