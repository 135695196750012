//Pixels ==========================================================================================
$mobile: 1024px;
$small-laptop: 1111px;
$medium-laptop: 1325px;
$laptop: 1440px;
$desktop: 1680px;
$screen-height: 100vh;
$header-height: 67px;
$dialog-v2-header-height: 64px;
$dialog-v2-footer-height: 70px;
$dialog-v2-margin: 32px;
$dialog-v2-non-content-height: $dialog-v2-header-height + $dialog-v2-footer-height + ($dialog-v2-margin * 2);
$navigation-bar-height: 51px;
$navigation-bar-component-without-padding: 46px;
$widget-content-height: calc(100vh - #{$header-height} - 4px);
$comment-icon-width: 70px;
$configuration-panel-width: 255px;
$folders-panel-width: 235px;
$series-step-height: 80px;
$widget-browser-height: 29px;
$title-widget-height: 55px;
$shared-material-width-label: 70px;
$shared-material-width-button: 127px;
$shared-material-width-body: calc(100% - #{$shared-material-width-label + $shared-material-width-button});
$browser-header-height: 30px;
$widget-content-view-as-customer: calc(100vh - #{$header-height} - #{$widget-browser-height} - #{$title-widget-height} - 50px);
$widget-content-width: 65%;
$widget-content-max-width: 65vw;
$mobile-widget-content-max-width: 80vw;
$navigation-bar-max-width: 70vw;
$widget-content-height-customer-hub-with-tabs: calc(100vh - #{$header-height} - #{$navigation-bar-height} - 50px);
$widget-content-height-customer-hub: calc(100vh - #{$header-height} - 20px);
$widget-content-height-without-hub-layout: calc(100vh - #{$navigation-bar-height} - 60px);
$trial-banner-height: 45px;
$screen-height-with-trial-banner: calc(100vh - 45px);
$header-height-with-trial-banner: 115px;
$trial-action-button-height: 25px;
$trial-banner-with-header: calc(100vh - 67px);
$screen-height-without-header-and-trial-banner: calc(#{$screen-height-with-trial-banner} - #{$header-height});
$test-account-height: 16px;
$nav-left-panel-height: 50px;
$header-template-left-panel: 60px;
$current-kit-height-left-panel: 116px;
$current-assets-menu-height: 146px;
$kit-add-step-button: 140px;
$sidebar-main-width: 73px;
$kit-steps-width: calc(100vw - #{$kit-add-step-button} - #{$configuration-panel-width} - #{$sidebar-main-width});
$customer-hub-with-header: calc(100vh - #{$title-widget-height});
$navigation-padding: 15px;
$info-size: 22px;
$slides-mode-navigation-bar-height: 55px;
$mobile-button-height: 60px;
$mobile-button-bottom-offset: 20px;
$product-feed-menu-width: 375px;
$task-edit-dialog-max-width: 1440px;
$intercom-height: 125px;
$slide-panel-border-width: 2px;
$widget-inline-menu-border-width: 8px;
$word-wand-suggestion-floating-window-height: 280px;

/** Meetings **/
$margin-left-meeting-details: 55px;

/** Select/Autocomplete **/
$max-select-option-width: 400px;

//Fonts ==========================================================================================
$main-Font: Poppins;
$title-Font: Quincy CF;
$secondary-font: OggRoman;
$date-and-time-font-family: Podkova;
$add-field-kpi-font-family: Podkova;
$section-title-kpi-font-family: Podkova;

//Colors ==========================================================================================
$pink-color: #fc8093;
$main-color: #793cfb;
$font-color-in-main-color: white;
$widget-elements-white-backgroung: #fff;
$subtext-grey: #5c5a5a;
$subtext-light-grey: #6f7282;
$description-grey: #818181;
$border-gray: #ced2d9;
$toggle-background-grey: #ced2d9;
$divider-color: #e4e2e8;
$toggle-grey: #f2f4f8;
$widget-entity-light-grey-background: #f8f9fb;
$primary-color: #0b3ff2;
$primary-branded-color: #2363e6;
$main-background-color: white;
$toast-success-color: #aec569;
$success-color: #99dbb1;
$snippet-button-color: #793cfc;
$billing-success-color: #039855;
$info-color: #588dfa;
$subtitle-info-color: #808080;
$error-color: #f1c948;
$notification-icon-color: #fe5e5b;
$text-highlight-background-color: #f5edd0;
$internal-data-background-color: #faf2d5;
$internal-data-background-color-hover: #f6ecb8;
$internal-data-border-color: #fdd047;
$checked-tag-color: #abc264;
$separator-color: #e9e8ed;
$error-message-color: #ee3543;
$delete-or-remove-color: #ee3543;
$danger-background-color: #f8aeb4;
$warning-background-color: #fbe5c5;
$text-color: #000000;
$placeholder-color: #959595;
$configuration-text-color: $subtext-grey;
$empty-state-text-color: $subtext-grey;
$subtitle-color: #525252;
$bullet-color: #b4b4b4;
$circle-border-color: #afafaf;
$steps-main-color: #6b6b6b;
$user-name-text-color: #6b6b6b;
$steps-empty-color: #cdd2da;
$template-title-color: #161616;
$placeholder-color: #545454;
$billing-feature-grey: #545454;
$billing-feature-almost-white: #f3f3f3;
$share-widget-text-color: #545454;
$steps-line-color: #cdd2da;
$contact-us-trial-banner-color: #ffffff;
$double-text-editor-milestone-color: #ffffff;
$trial-banner-color: #3166de;
$trial-action-button-color: #ffffff;
$widget-gallery-background-color: #f5f5f5;
$chosen-text-snippet-background-color: #ede5ff;
$chosen-text-snippet-color: #6415fa;
$last-completed-milestone-title: #00c8a9;
$followers-button-color_with-followers: rgba(255, 255, 255, 0.6);
$owner-link-background-color: rgba(255, 255, 255, 0.7);
$refine-button-filter-color: #ffffff;
$priority-cell: #ababab;
$individual-mode-label-color: #f4a5ec;
$blocker-dialog-bg-color: #ffffff;
$placeholder-content-background: #f3f3f3;
$placeholder-content-color: #c4c4c4;
$branding-subtitle-color: #cacbcb;
$megaphone-icon-color: #f1c948;
$suggestions-color: #818181;
$avatar-icon-background-color: #dddddd;
$avatar-icon-font-color: #414141;
$linked-button-background-color: rgba(98, 54, 255, 0.07);
$help-icon-color: #ffffff;
$help-icon-background-color: #fed758;
$status-text-color: #6d7883;
$sidebar-color: #000;
$navigation-buttons-prefix-color: #6f7282;
$dashed-line-timeline: #ced2d9;
$navigation-buttons-prefix-color: $subtext-light-grey;
$account-card-hover-background-color: #f3f3f3;
$placeholder-editor-color: #808080;
$asset-hover-background-color: #f1f1f1;
$text-snippet-hover-background-color: #f1f1f1;
$comments-dialog-height: 45vh;
$notify-via-email-height: 270px;
$comments-title-height: 75px;
$kpi-short-text-container: 400px;
$comments-title-height: 86px;
$dialog-footer-background-color: #f3f3f3;
$field-selector-border-color: #dfe1e3;
$internal-border-color: #f6c511;
$textbox-backgrount-color: #ffffff;
$subtext-color: #545454;
$border-color: #d9d9d9;
$textbox-border-color: #d9d9d9;
$textbox-hover-border-color: #8b8d91;
$show-more-button-border-color: #ced2d9;
$show-more-button-background-color: #fff;
$kpi-value-color: #59c5aa;
$kpi-column-color: #e9e8ed;
$avatar-border-color: #e9e8ed;
$collaborators-info-color: #626262;
$intro-highlight-box-shadow: rgba(174, 217, 238, 0.5);
$field-name-color: #6f6d6d;
$login-button-color: #ffffff;
$section-kpi-seperator: #ece8ed;
$set-duration-highlight-text: #3e76e8;
$start-from-stratch-button-color: #e6daff;
$start-from-stratch-label-color: #6415fa;
$widget-list-hierarchy-line: #e4e2e8;
$saving-text-color: #94ad48;
$freestyle-texteditor-color: #545454;
$callout-opened-border-color: #d5d5d5;
$task-plan-empty-state-border-color: #d5d5d5;
$activity-metric-footer: #9d9d9d;
$field-color: #5d5d5d;
$action-button-description: #4c4c4c;
$template-cell-background-color: #2a1244;
$minimap-tab-background-color: #edeced;
$minimap-widget-background-color: #d4d4d4;
$scrollbar-thumb-color: #c1c1c1;
$menu-item-hover-color: #f5f5f5;
$white-color: #ffffff;
$black-color: #000000;

/** Files **/
$file-background-color: #fff;
$file-border-color: #d9d9d9;

/** Shared Materials **/
$share-material-action-button-color: #793cfb;
$share-material-file-label-color: #abc264;
$share-material-link-label-color: #2363e6;
$share-material-description-color: #6f7282;
$share-material-empty-state-background: #f9f2d8;

/** Table and charts **/
$sync-icon-table-widget: #898989;
$expand-icon-color: #757575;

/** Widget container **/
$widget-container-background-color: #ffffff;
$widget-container-border-color: #d9d9d9;
$widget-container-font-color: #4d4d4d;
$icon-wrapper-border-color: #000000;
$asset-menu-index: #ffffff;
$test-account-border-color: #0b3ff2;
$selected-item-background: #ededed;
$all-accounts-hover-color: #ebebeb;
$improve-text-suggestion-color: #ebebeb;
$all-accounts-hover-border-color: #656363;
$widgets-header-red-oval: #ff6058;
$widgets-header-green-oval: #28ca41;
$widgets-header-orange-oval: #ffbe2f;
$widgets-header-color: #dee1e6;
$add-column-border-top-color: #e4e4e4;
$widget-description-color: #515151;
$widget-title-color: #000000;
$widget-secondary-title: #585858;
$widget-information-panel: #000000;
$widget-browser-text: #000000;
$icon-add-section-color: #0000;
$watermarks-color: #4e4e4e;
$widget-alert-background: #ffd4de;
$widget-warning-background: #fff6bf;
$widget-success-background: #dbf8e8;
$widget-general-background: #dfebf7;
$widget-max-width: 1040px;

/** Task status select **/
$task-status-not-started-background: #dfe1e3;
$task-status-not-started-color: #6d7883;
$task-status-in-progress-background: #c3e5f6;
$task-status-in-progress-color: #0885c1;
$task-status-completed-background: #b9f2cd;
$task-status-completed-color: #00a63b;

/** Tasks duration indicator **/
$tasks-duration-on-track-background: #d9f2db;
$tasks-duration-on-track-color: #3e6b41;
$tasks-duration-potential-delay-background: #fff3d0;
$tasks-duration-potential-delay-color: #f2af34;
$tasks-duration-delayed-background: #ffcdcc;
$tasks-duration-delayed-color: #fe5e5b;
$tasks-duration-not-started-background: #f2f2f2;
$tasks-duration-not-started-color: #929292;

// Menu
$menu-color: #4f4f4f;
$item-hover-background-color: #696969;
$item-title-color: #ffffff;

/** Fancy tag **/
$tag-shadow-color: #4a4a4a;
$tag-background-color: #ffffff;
$tag-color: #000000;

$gray-action-button-menu-hover-color: #dee1e6;
$purple-action-button-menu-hover-color: #6d36e2;

$select-options-keyboard-navigation-highlight: #f5f5f5;
$select-options-hover-highlight: #e6e6e6;

$generated-content-options-background: #ebebeb;
$insights-mode-background-color: #e9effc;

//Mixin ==========================================================================================
@mixin inheritWrappedStyle() {
    width: inherit;
    display: inherit;
    text-decoration: inherit;
    color: inherit;
    flex-grow: inherit;
    flex-shrink: inherit;
    flex-basis: inherit;
}

@mixin insightsModeContent {
    background-color: $insights-mode-background-color;
}

@mixin insightsModeBorder {
    outline: 4px solid $primary-branded-color;
}

/** Common **/
@mixin title {
    font-size: 18px;
    font-weight: bold;
    color: $text-color;
}

@mixin shareMenu {
    width: 309px;
    right: 40px;
    z-index: 100;
}

@mixin titleBold {
    @include title();
    font-family: $title-Font;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}

@mixin description($fontSize: 12px, $color: $text-color) {
    font-size: $fontSize;
    color: $color;
}

@mixin descriptionWithOpacity($fontSize: 12px, $color: $text-color, $opacity: 0.7) {
    @include description($fontSize, $color);
    opacity: $opacity;
}

@mixin midTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000000;
}

@mixin notificationsViaEmail {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

@mixin typeAComment {
    width: 100%;
    flex: 1 1 auto;
    background-color: $blocker-dialog-bg-color;
    font-size: 13px;
}

@mixin emptyStateText {
    color: $empty-state-text-color;
    font-size: 12px;
    font-family: $main-Font;
}

@mixin textualButtonLabel {
    display: flex;
    align-items: center;
    gap: 8px;
}

@mixin svgButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 180px;
    height: 156px;
    color: #000000;
    fill: #000000;
    g {
        fill: #000000;
    }
    font-family: $main-Font;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        color: inherit;
        outline: none;
    }
}

@mixin navigationBarPadding {
    padding: 15px 63px 0;
}

@mixin sharedWidgetBtn {
    font-size: 12px;
    border-radius: 100px;
    width: 28px;
    height: 28px;
    margin-right: 15px;
}

// used for replacing missing avatars
@mixin dashedCircleBorder {
    border: 1px dashed $circle-border-color;
    border-radius: 50%;
}

@mixin loading {
    font-family: $secondary-font;
    font-size: 28px;
    font-weight: bold;
    color: $text-color;
}

@mixin textStyle {
    font-family: $title-Font;
    font-size: 28px;
    font-weight: bold;
    color: #000000;
}

@mixin onboardingTitle {
    font-family: $title-Font;
    font-size: 60px;
    text-align: center;
    color: #000000;
    margin-top: 70px;
}

@mixin bullet {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 15px;
    color: $bullet-color;
}

@mixin autocompleteDropdownRTL {
    left: auto;
    right: 0px;
    width: 0;
}

@mixin colorCircle {
    width: 10px;
    height: 10px;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-right: 10px;
}

@mixin ovalMenuItemIcon {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

@mixin ovalWidgetHeader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
}

@mixin greenOval {
    width: 7px;
    height: 7px;
    background-color: #13c32c;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin arrowUp($color: white) {
    position: relative;
    margin-top: 10px;
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color;
        margin-top: -10px;
        margin-right: -10px;
    }
}

@mixin arrowDown($color: white) {
    position: relative;
    margin-bottom: 10px;
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $color;
        margin-right: -10px;
        right: 50%;
        bottom: -10px;
    }
}

/** Icon wrapper **/
@mixin iconWrapperBorderColor {
    border-radius: 50%;
    border: solid 1px $icon-wrapper-border-color;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

/**left panel configuraton **/
@mixin configurationPanel {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    box-shadow: 15px 2px 12px 0 rgba(0, 0, 0, 0.07);
    align-items: center;
}

@mixin widgetConfigurationPanel {
    width: 255px;
    min-width: 255px;
    @include configurationPanel();
}

@mixin widgetDescriptionPanel {
    font-size: 12px;
    color: $widget-description-color;
}

@mixin widgetPanelConfigurationName {
    font-size: 14px;
    color: #000000;
}

@mixin accountsDetailsTitleRegularWeight {
    font-size: 14px;
    color: #000000;
}

@mixin accountsDetailsTitle {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
}

@mixin widgetTitle {
    font-size: 14px;
    font-weight: 600;
    color: $widget-title-color;
}

@mixin widgetSecondaryTitle {
    font-size: 12px;
    color: $widget-secondary-title;
}

@mixin widgetInformationPanel {
    font-size: 12px;
    color: $widget-information-panel;
}

@mixin center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin horizontalCenter {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@mixin pageTitle {
    font-family: $title-Font;
    font-size: 60px;
    text-align: center;
    color: $text-color;
    @media (max-width: $mobile) {
        font-size: 34px;
    }
}

@mixin onBoardingColorsTitle {
    font-size: 20px;
    line-height: 1;
    color: #000000;
}

@mixin pageSubtitle {
    font-size: 24px;
    color: $text-color;
    font-family: $main-Font;
    margin-top: 10px;
    text-align: center;
    @media (max-width: $mobile) {
        font-size: 16px;
    }
}

@mixin centeredStickyElement() {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin ellipsisMultiLine($lineCount: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

@mixin mobileButton {
    @media (max-width: $mobile) {
        width: 160px;
        bottom: $mobile-button-bottom-offset;
        height: $mobile-button-height;
        @include centeredStickyElement();
    }
}

@mixin hideScrollbar {
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

@mixin flexCenterJustifyContent($justify-content) {
    display: flex;
    align-items: center;
    justify-content: $justify-content;
}

@mixin textFieldInput {
    font-size: 18px;
    color: #000000;
}

@mixin textField {
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    padding: 0 20px;
    display: flex;
    height: 40px;
    width: 400px;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    &.large {
        height: 49px;
    }
    &.small {
        height: 24px;
        padding: 0 5px;
    }
    &.errorBorder {
        border: solid 1px #ee3543;
    }
    &.disabled {
        opacity: 0.2;
        border: solid 1px #d9d9d9;
        pointer-events: none;
    }
    &:hover {
        border: solid 1px #8b8d91;
    }
}

@mixin smallTitle {
    font-size: 12px;
    color: #000000;
}

@mixin sticky {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 0;
}

@mixin errorMessageContainer {
    color: $error-message-color;
    font-size: 14px;
    font-weight: 600;
    height: 16px;
    padding-top: 10px;
}

@mixin formEditKpiField {
    height: 40px;
    border-radius: 5px;
    word-break: break-all;
}

// Styles for a MUI tooltip (use when using the component is not viable)
%mui_tooltip {
    background-color: #000;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    max-width: 300px;
    word-wrap: break-word;
    white-space: break-spaces;
    font-weight: 500;
    line-height: 1.4em;
    border-radius: 4px;
}

@mixin robotIconOval {
    content: ' \25CF';
    font-size: 20px;
    color: $primary-branded-color;
    position: absolute;
    top: -9px;
    right: 10px;
}

@mixin widgetPanel {
    height: calc(100vh - #{$header-height} - 5px);
}

@mixin widgetsModuleContainer {
    border-radius: 10px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
    min-height: calc(#{$widget-content-height} - 60px);
}

@mixin internalWidgetBackground {
    background-image: url('assets/images/dot.png');
}

%widget {
    background-color: $widget-container-background-color;
    height: fit-content;
    position: relative;
    border-radius: 6px;
}

%widgetsModuleHeader {
    width: 100%;
    padding: 6px 11px 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    box-sizing: border-box;
    margin-bottom: -4px;
    height: 35px;
}

@mixin betaTag {
    position: absolute;
    content: 'Beta';
    background: #0b3ff2;
    width: 26px;
    height: 13px;
    margin: 0 2px 2px;
    font-family: $main-Font;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: 1px #ffffff solid;
    text-align: center;
    line-height: 14px;
}

@mixin presentationNavigationMenu {
    position: fixed;
    bottom: 15px;
    left: 10px;
    z-index: 102;
    display: flex;
    align-items: center;
    padding: 15px 5px 15px 15px;
    border-radius: 10px;
    background-color: #000000cc;
    color: white;
    user-select: none;
    max-height: $slides-mode-navigation-bar-height;
    .button {
        border-color: white;
        min-width: fit-content;
        margin-right: 10px;

        &.arrowButton {
            padding: 0 7px;

            .arrow {
                fill: white;
            }
        }

        &.backButton {
            padding: 0 20px;
        }

        &:hover {
            background-color: unset;
        }
    }

    .pageCount {
        flex-shrink: 0;
        margin: 0 30px 0 20px;
    }
}

$slides-mode-content-top: calc(#{$title-widget-height} + 10px);
@mixin slidesMainContent {
    // Scrollbars always visible
    & > div::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
    }

    @media (max-width: $mobile) {
        gap: 15px;
        padding-top: 0;
        position: fixed;
        top: $slides-mode-content-top;
        display: flex;
        flex-direction: column;
        align-items: center;
        // 25px = spacing
        height: calc(100vh - #{$title-widget-height} - #{$slides-mode-navigation-bar-height} - 35px);
    }
}

@mixin closingSlide {
    pointer-events: none;
}

@mixin inputPlaceholder {
    color: $placeholder-content-color;
    font-style: italic;
}

@mixin deleteButton {
    // works for assets/icons/deleteComment.svg
    cursor: pointer;

    path {
        stroke: $text-color;
    }

    &:hover {
        background: rgba(red($error-message-color), green($error-message-color), blue($error-message-color), 0.1);

        path {
            stroke: $error-message-color;
            transition: stroke 0.1s ease-in;
        }
    }
}

@mixin actionButton {
    display: flex;
    cursor: pointer;
    transition: background 0.2s ease-in;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &:hover {
        background: $separator-color;
        transition: background 0.2s ease-in;
    }
}

@mixin taskActionButton {
    @include actionButton();
}

@mixin taskActionSelectionBorder {
    border: 1px solid $separator-color;
    border-radius: 4px;

    &.selected {
        border: 2px solid $primary-branded-color;
    }
}

@mixin taskActionSeparator {
    height: 14px;
    border-left: 1px solid $separator-color;
}

@mixin confirmDialogTitle {
    max-width: 400px;
    @include ellipsis();
}

@mixin addTaskItemButton {
    font-size: 14px;
    cursor: pointer;
    color: $subtext-color;
    font-family: $main-Font;
}

@mixin completedTaskTextWithStrike {
    opacity: 0.2;
    text-decoration: line-through;
}

@mixin completedTaskTextNoStyle {
    color: $subtext-color;
}

@mixin addItemTextButton {
    font-size: 12px;
    cursor: pointer;
    color: $primary-color;
}

@mixin subTextBilling {
    font-size: 11px;
    color: $subtext-light-grey;
    width: 124px;
    white-space: nowrap;
}

@mixin subTextTagBilling {
    background-color: rgba($billing-feature-grey, 0.1);
    border-radius: 20px;
    padding: 3px 10px 2px;
}

@mixin textAreaLabel {
    color: $subtitle-info-color;
    font-size: 9px;
    line-height: 1.9;
}

@mixin notificationEvent {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    transition: background-color 0.2s ease-in;
    border-radius: 5px;
    &:hover {
        transition: background-color 0.2s ease-in;
        background-color: $placeholder-content-background;
    }
    cursor: default;
}

%divider {
    height: 19px;
    border-left: 1px solid $separator-color;
}

// ANIMATIONS

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes fadeInWithDelay {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    50% {
        visibility: visible;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@mixin rotatingAnimation($duration: 1s) {
    -webkit-animation: rotating $duration linear infinite;
    -moz-animation: rotating $duration linear infinite;
    -ms-animation: rotating $duration linear infinite;
    -o-animation: rotating $duration linear infinite;
    animation: rotating $duration linear infinite;

    @-webkit-keyframes rotating /* Safari and Chrome */
    {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

%TAG {
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 5px;
}

%CLICKABLE-TEXT {
    font-size: 12px;
    color: $primary-branded-color;
    cursor: pointer;
}

@mixin dialogContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

@mixin dialogContent {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    min-height: 0;
    max-height: 65vh;
}