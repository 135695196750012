@import '../../variables';
@import '../../aiVariables';

.templateNameContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.templateName {
    font-size: 14px;
    font-weight: bold;
}

.disabledName {
    pointer-events: none;
    text-decoration: none;
    cursor: default;

    .templateName {
        font-weight: 600;
    }
}

.indicationTooltip {
    white-space: break-spaces;
}

.tag {
    @extend %AI_COLOR_BACKGROUND;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0 8px;
    border-radius: 2px;
    color: #fff;
    font-size: 8px;
    font-weight: normal;
    min-width: 121px;
    max-width: 121px;
    height: 14px;
    cursor: default;
    user-select: none;

    svg {
        width: 8px;
        height: 8px;
    }

    span {
        margin-top: 1px;
    }
}

.widgetsContentPanel {
    @include widgetPanel();
    border-right: 1px solid $separator-color;
    height: 100%;
    padding-left: 10px;
}

.leftPanelContainer {
    @include widgetConfigurationPanel();
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .header {
        border-bottom: 1px solid $widget-container-border-color;
        margin: 0 27px;
    }

    .content {
        display: flex;
        height: $widget-content-height;
        flex-direction: row;

        &.withTrialBanner {
            height: calc(#{$widget-content-height} - #{$trial-banner-height});
        }

        .widgetsContent {
            height: inherit;
            position: relative;
            width: 100%;
            display: inline-grid;

            .steps {
                margin: 0 30px;
            }

            .withoutSteps {
                margin: 30px;
            }
        }

        .widgetsListPanel {
            min-width: $configuration-panel-width;
            max-width: $configuration-panel-width;
            border-right: 1px solid $widget-container-border-color;
            height: inherit;

            .widgetsListMenu {
                > [class*='container'] {
                    height: calc(100% - #{$header-template-left-panel});
                    overflow-y: auto;
                    padding-right: 20px;
                }
            }
        }

        .widgetConfigurationPanel {
            border-left: 1px solid $widget-container-border-color;
            height: inherit;
            overflow-y: auto;
            flex-shrink: 0;
        }
    }

    .checkIcon {
        width: 5px;
        height: 5px;
    }
}

.widgetsContainer {
    @include widgetsModuleContainer();
    overflow: auto;
    min-height: inherit;

    &.steps {
        min-height: calc(#{$widget-content-height} - 30px - #{$series-step-height});
        height: calc(#{$widget-content-height} - 30px - #{$series-step-height});
    }

    &.stepsWithTrialBanner {
        min-height: calc(#{$widget-content-height} - 30px - #{$series-step-height} - #{$trial-banner-height});
        height: calc(#{$widget-content-height} - 30px - #{$series-step-height} - #{$trial-banner-height});
        margin: 0 30px;
    }
}

.insightsModeContent {
    @include insightsModeContent();
}

.insightsModeBorder {
    @include insightsModeBorder();
}

.numberOfAccountsContainer {
    display: flex;
    text-align: left;

    .numberOfAccounts {
        margin-right: 5px;
    }
}

.numberOfAccountsColumn {
    cursor: pointer;
}
