.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}

.checkboxOptionContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  text-align: initial;
  font-size: 14px;
}

.checkboxInput {
  align-self: flex-start;
  margin-top: 2px;
}

.checkboxOptionTexts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.optionText {
  display: flex;
  gap: 15px;
}

.radioButtonOptionContainer {
  margin-bottom: 10px;
}

.radioButtonOptionText {
  font-size: 14px;
}

.tooltip {
  min-width: unset;
}

.infoIconWrapper {
  height: 19px;
}

.subtitle {
  color: #a6a6a6;
}