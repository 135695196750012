.container {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 410px;
}

.customWidthInput {
    height: 40px;
}

.pxLabel {
    margin-left: 8px;
    font-size: 14px;
    color: #666;
}

.popupSize {
    font-weight: bold;
}

.sizeSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}