@import '../../variables';
@import '../../aiVariables';

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 69px;
  width: 100%;
  box-sizing: border-box;
  padding: 23px 30px 22px 26px;
}

.headerActions {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.customHeader {
  height: unset;
}

.body {
  display: flex;
  max-height: calc(92vh - 70px - 60px);
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 26px 15px 30px 26px;
  border-right: 1px solid $divider-color;
  width: 245px;
}

.step {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color ease-in 0.2s;

  &:hover {
    background-color: $menu-item-hover-color;
    cursor: pointer;
  }
}

.stepBullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  box-sizing: border-box;
}

.stepName {
  font-size: 14px;
  color: $field-color;
}

.currentStep {
  .stepBullet {
    border: none;
    background-color: $ai-pink;
  }

  .stepName {
    @extend %AI_TEXT;
  }
}

.completedStep {
  .stepBullet {
    border: none;
    background-color: $checked-tag-color;
  }

  .stepName {
    opacity: 0.5;
    text-decoration: line-through;
  }
}

.invalidStep {
  .stepBullet {
    border: none;
    background-color: $error-message-color;
  }

  .stepName {
    color: $error-message-color
  }
}

.completedStepCheckmark {
  width: 8px;
  height: 6px;
}

.invalidStepIcon {

}

.mainContent {
  padding: 26px 30px 30px 25px;
  box-sizing: border-box;
  overflow: scroll;
  scrollbar-gutter: stable;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $dialog-footer-background-color;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 30px 13px 26px;
}

.buttons {
  display: flex;
  gap: 30px;
}

.prevButton {
  font-size: 14px;
  color: #000;
}

.nextButton {
  padding: 8px 22px;
}

.disabled {
  pointer-events: none;
}