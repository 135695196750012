@import '../../../variables';

.container {
    font-size: 12px;

    .itemTextContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .text {
            font-size: 12px;
        }
    }
}

.fullTextContainer {
    align-items: flex-start;
}

.plaintext {
    white-space: pre-line;
}