@import '../../../variables';

$dialogWidth: 440px;

.error {
    position: absolute;
    bottom: 68px;
    right: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}

.mandatoryDiv {
    font-size: 13px;
}

.crmObjectEditDialog {
    max-width: 100%;
    @include dialogContainer();
}

.dialogContent {
    padding: 30px 26px 20px 14px;
    @include dialogContent();
    
    @media (max-width: $mobile) {
        width: unset;
    }
}

.fieldInput {
    margin-bottom: 25px;
    display: inline-block;
    padding-right: 15px;
    box-sizing: border-box;
    vertical-align: top;
}

.emptyState {
    display: flex;
    justify-content: center;
    margin: 30px 0 25px;
    font-size: 14px;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    margin: 0 -15px 0 0; // Negative margin to offset the padding on fieldInput
}