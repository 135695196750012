@import '../../variables';

.textField {
    position: relative;
}

.container {
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    padding: 0 20px;
    display: flex;
    height: 40px;
    width: 400px;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;

    &.large {
        height: 49px;
    }

    &.small {
        height: 24px;
        padding: 0 5px;
    }

    &.errorBorder {
        border: solid 1px #ee3543;

        &:active {
            border: 1px solid lighten(#ee3543, 10%);
        }
    }

    &.disabled {
        opacity: 0.2;
        border: solid 1px #d9d9d9;
        pointer-events: none;
    }

    &:not(.errorBorder):hover {
        border: solid 1px #8b8d91;
    }
}

.label {
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 11px) scale(1);
    transition: all 0.1s ease-in-out;
    color: #959595;

    &.cornerLarge {
        font-size: 12px;
        color: #000000;
    }

    &.cornerRegular {
        font-size: 10px;
        color: #000000;
    }

    &.large {
        font-size: 20px;
    }

    &.regular {
        font-size: 14px;
    }
}

.large {
    .active {
        transform: translate(0, 4px);
        opacity: 0.7;
        font-size: 12px;
    }
}

.active {
    transform: translate(0, 4px);
    opacity: 0.7;
    font-size: 10px;
}

.container:active {
    border: solid 1px #8b8d91;
}

input {
    border: none;
    outline: 0;
    width: 100%;

    &.input {
        z-index: 1;
        background: none;
    }
}

input:focus {
    outline: none;
}

input .error {
    border: solid 1px #ee3543;
}

.numberPickerFix {

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        position: relative;
        bottom: 10px;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

.rightIcon,
.loader {
    display: flex;
    align-items: center;
}

.error {
    font-size: 10px;
    font-weight: 600;
    color: #ee3543;
}

.helperText {
    font-size: 12px;
    color: #5c5a5a;
    font-family: $main-Font;
    line-height: 1.67;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input:disabled {
    background: #ffffff;
    color: black;
}

input[type='text']:disabled {
    background: #ffffff;
}