@import '../../../variables';
@import '../../../aiVariables';

.tooltip {
  @extend %AI_STARS_BACKGROUND;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  z-index: 100000;
  color: #fff;
  font-size: 14px;
  font-family: $main-Font;
  font-weight: 500;
  white-space: break-spaces;
}

.arrow {
  @include arrowDown(#9531F9);
}

.visibleTooltip {
  animation: inAnimation 0.2s ease-in-out;
}

.unmountTooltip {
  animation: outAnimation 0.2s ease-in-out;
}

.tooltipContent {
  padding: 8px 30px 6px 10px;
  pointer-events: none;
  position: relative;
}

.closeIcon {
  path, circle {
    fill: #fff;
  }
}

.hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -10px;
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outAnimation {
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}