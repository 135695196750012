@import '../../variables';

.editBoxInput {
  display: flex;
  width: 100%;
  align-items: center;
  height: 40px;
  padding: 6px;

  .input {
    width: calc(100% - 50px);
    display: flex;
    height: 30px;

    input {
      font-size: 13px;
    }

    label {
      line-height: 0;
    }
  }

  .displayText {
    @include ellipsis();
    font-size: 13px;
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    padding-left: 15px;
    padding-top: 4px;
  }

  .placeholder {
    padding-left: 15px;
    padding-top: 4px;
  }
}

.placeholder {
  @include inputPlaceholder();
}

.editTextArea {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  max-height: 67px;
  padding: 6px;
  overflow-y: auto;
  box-sizing: border-box;

  .textArea {
    padding: 1px;
    width: calc(100% - 50px);
    height: fit-content;

    [class*='DraftEditor-root'] {
      padding: 3px 0 0 10px;
      font-size: 13px;
      min-height: unset;
    }
  }

  .displayText {
    @include ellipsis();
    font-size: 13px;
    display: inline-block;
    white-space: pre-wrap;
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
    padding: 4px 0 3px 16px;
    overflow-y: auto;
  }

  .placeholder {
    padding: 4px 0 3px 16px;
  }
}

.widgetsSelectMenu {
  width: 100%;
  height: fit-content;
  max-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;

  .widgetItemWrapper {
    width: 100%;
    border-radius: 0;
  }

  .menuItem {
    border: 1px solid $widget-container-border-color;
    &.selected {
      border-radius: 0;
    }
    max-width: 100%;
    min-width: 100%;
  }
}

.widgetsSelect {
  height: fit-content;
  max-height: 104px;
  overflow-y: auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: height 0.2s ease-in, max-height 0.2s ease-in;
  min-height: 50px;

  .widgetsSelectContainer {
    max-height: 104px;
    overflow: auto;
  }

  .placeholder {
    padding-left: 15px;
    padding-top: 15px;
  }

  .menuItem {
    height: 28px;
    width: 100%;
    border: 1px solid $widget-container-border-color;
    padding: 10px;
    margin-bottom: 5px;

    &.disabled {
      cursor: not-allowed;
      @include internalWidgetBackground();
    }

    &.selected {
      background: $selected-item-background;
    }

    [class*='internalIcon'] {
      top: 8px;
      left: 58px;
    }
  }

  .displayMode {
    width: 100%;
    height: fit-content;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .menuItem {
      max-width: 100%;
      width: fit-content;
      min-width: 100px;
      margin: 5px;
      transition: opacity 0.1s ease-in, width 0.1s ease-in;
      padding: 0 5px 0 0;

      height: 40px;

      img {
        padding: 0 4px;
      }

      &.hidden {
        opacity: 0;
        width: 0;
        display: none;
        min-width: 0;
        padding: 0;
        margin: 0;
        transition: opacity 0.1s ease-in, width 0.1s ease-in;
      }
    }
  }
}

.widgetItemWrapper {
  position: relative;

  .selectedIcon {
    position: absolute;
    right: 20px;
    aspect-ratio: 1;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.inlineEditButton {
  width: calc(100% - 50px);
  > div {
    transition: all 0.2s ease-in;
    width: fit-content;
  }
  padding: 10px;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in;
  .input {
    width: fit-content;
    input {
      color: var(--text-field-color, $widget-container-background-color);
      text-align: center;
      width: 400px;
      min-width: 100%;
    }
  }

  .button {
    transition: all 0.2s ease-in;
    width: fit-content;
    padding: 0 15px;
  }
}
