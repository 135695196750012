@import '../../../../aiVariables';

.successDialogHeader {
  @extend %AI_STARS_BACKGROUND;
  color: #fff;
  font-size: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 23px 26px 22px;
  box-sizing: border-box;
}

.successDialogBody {
  padding: 27px 26px 30px;
  width: 600px;
  box-sizing: border-box;
  white-space: break-spaces;
  color: #000;
  font-size: 14px;
}

.successDialogButton {
  padding: 8px 22px;
}