@import '../../../../../variables';

.row {
  display: flex;
  justify-content: space-between;
}

.description {
  color: $field-color;
  white-space: break-spaces;
  font-size: 14px;
}

.button {
  padding: 8px 50px;
}

.filesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 400px;
}

.fileTextInputs {
  min-width: 308px;
}