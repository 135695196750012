@import '../../../variables';

.additionalDetailsDialog {
    max-width: 100%;
    @include dialogContainer();
}

.dialogBody {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;
    @include dialogContent();
}

.inputContainer {
    textarea {
        // --font-family is a variable set in the element style.
        // This is done since textarea has a font-family set in index.scss and none of the props in input affect the element directly.
        font-family: var(--font-family);
    }
}