.infoIcon {
    margin-left: 5px;
}

.templateBorder {
    margin: 0 5px;
}

.automationField {
    margin: 0 5px;
    font-weight: normal;
}

.seriesTemplateTitle {
    display: flex;
    align-items: center;

    .arrow {
        margin-left: 5px;
        background-color: transparent;

        &:hover {
            background-color: #ebebeb;
        }
    }
}
