@import '../../../variables';

.addAttachmentContainer {
    margin: 10px 0 0 10px;
    width: fit-content;
    display: flex;
}

.attachmentsList {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding-top: 10px;
}

.addAttachmentButton{
    @include actionButton();
    padding: 10px 5px;
}

.attachmentText {
    margin-left: 10px;
    font-size: 12px;
}
