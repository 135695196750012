@import '../../../../variables';

.body {
  width: 692px;
  max-height: 570px;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:last-of-type {
    padding-bottom: 30px;
  }
}

.inputTitle {
  font-size: 14px;
  color: #000;
}

.highlightError {
  color: $error-message-color;
}

.longTextContainer {
  height: auto;
}

.longTextInput {
  min-height: 200px;
  padding-left: 20px;
}

.choiceInputContainer {
  gap: 20px;
}