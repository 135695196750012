@import '../../variables';
@import '../../aiVariables';

.textButton {
    padding: 0;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-family: $main-Font;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 40px;
    background: none;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.blue {
        color: $primary-branded-color;
        &:hover:not(:disabled) {
            color: darken($primary-branded-color, 5%);
        }
    }

    &.large {
        font-size: 20px;
    }

    &.slim {
        font-size: 12px;
    }
}
