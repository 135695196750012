.wrapper {
  width: fit-content;
}

.button {
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.loader {
  margin-top: -8px;
}