@import '../../../aiVariables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(8px);
  pointer-events: none;
  user-select: none;
}

.dialog {
  max-width: 1042px;
}

.title {
  @extend %AI_TEXT;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;

  span {
    flex: 1;
  }
}

.titleIcon {
  width: 12px;
  height: 12px;
}

.disabled {
  pointer-events: none;
}