@import '../../variables';
@import '../../aiVariables';

.notificationContainer {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(162, 162, 162, 0.3);
    font-weight: bolder;
    color: white;
    gap: 15px;

    &.success {
        background-color: $toast-success-color;
    }

    &.info {
        background-color: $info-color;
    }

    &.delete {
        background-color: $info-color;
    }

    &.error {
        background-color: $error-color;
    }

    &.ai_success {
        background: $ai-background-color-gradient;
    }

    &.invalid {
        background-color: $error-message-color;
    }
}
