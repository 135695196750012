@import '../../../../variables';

%FLEX_ROW {
    display: flex;
    align-items: center;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.actions {
    @extend %FLEX_ROW;
    justify-content: space-between;
    margin-top: 10px;
}

.textarea {
    font-weight: 400;
    font-size: 14px;
}

.lineAboveButtons {
    font-size: 12px;
}

.belowCommentInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.buttonsArea {
    @extend %FLEX_ROW;
}

.attachments {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 90px;
    overflow: auto;

    @media (max-width: $laptop) {
        max-height: 50px;
    }
}

.attachment {
    div:first-of-type {
        margin: 0;
    }
}