@import '../../variables';

.container {
    align-items: center;
    display: flex;

    .icon {
        padding-right: 10px;
    }

    .dot {
        padding-right: 15px;
        padding-left: 15px;
        color: #959595;
    }

    .gotoKitLink {
        padding-left: 28px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.templateName {
    width: fit-content;
    padding: 1px 4px;
    height: 16px;
    border-radius: 2px;
    font-size: 12px;
    color: #000000;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    background-color: #ffffff4d;
    margin-bottom: 35px;
}

.nestedKitContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.separator {
    width: 0;
    height: 19px;
    border-right: 1px solid $separator-color;
    margin: 0 8px;
}