.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: initial;
}

/* Style for showing full text with internal scrolling */
.fullText {
    width: 100%;
    overflow-wrap: break-word;
}