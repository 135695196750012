@import '../../../../../../variables';

.toolBar {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    height: 10px;
    z-index: 500;

    @media (max-width: 537px) {
        display: none;
    }

    .icon {
        width: 13px;
        height: 13px;
        margin: 0 3px;
        cursor: pointer;
        &.blackIcon {
            color: black;
            fill: currentColor;
            * {
                fill: currentColor;
            }
        }
    }
    
    .robotIcon {
        margin: 0 3px;
        cursor: pointer;
    }
}

.divider {
    margin-left: 10px;
    margin-right: 10px;
    border-left: 1px solid $divider-color;
    height: 19px;
}

.copyWrapper {
    display: flex;
    place-items: baseline;

    .copyIcon {
        background-color: transparent;
        min-width: 0;
        height: 0;

        &:hover {
            background-color: transparent;
        }
    }
}
