@import '../../../../../variables';

.dialog {
  margin-top: -60px;
}

.dialogContainer {
  height: calc(90vh - 30px);
  min-width: 90vw;
  max-width: 95vw;
}

.dialogContent {
  padding: 20px 30px;
  font-size: 14px;
}

.dialogText {
  padding-bottom: 20px;
  color: #000;
}

.headerRow > span {
  font-family: $main-Font;
}

.bodyRow [class*='label'] {
  word-break: break-word;
}

.textCell {
  @include ellipsis;
}

.fileLocation {
  display: flex;
  align-items: center;
  gap: 3px;
}

.locationTypeIcon {
  display: flex;
  align-self: baseline;
}

.uploadedToIcon {
  width: 12px;
}

.downloadIcon {
  cursor: pointer;
}