@import '../../../../variables';

.addContentManually {
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: $subtext-color;
}

.clickableText {
  text-decoration: underline;
  cursor: pointer;
  transition: color ease-in-out 0.2s;

  &:hover {
    color: darken($subtext-color, 30%);
  }
}

.submitButton {
  padding: 9px 35px;
}

.warnManualContentDialogBody {
  font-size: 14px;
  padding: 26px 30px 40px 25px;
  width: 550px;
  box-sizing: border-box;
  white-space: break-spaces;
}