.container {
    width: 505px;
    padding: 30px;
}

.infoMessage {
    font-size: 12px;
    margin-bottom: 20px;
}

.optionsContainer {
    display: flex;
    justify-content: space-between;

    .radioButtonsContainer {
        margin-top: 10px;
        width: 100%;

        .radioButton {
            margin-top: 10px;
            margin-bottom: 80px;
        }
    }

    .templatesListContainer {
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
        display: flex;
        gap: 6px;

        > * {
            width: 100%;
        }
    }

    .disabled {
        opacity: 0.3;
    }
}

.dropDown {
    margin-bottom: 10px;
}

.checkbox {
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    gap: 20px;
}

.errorMessage {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #ee3543;
}
